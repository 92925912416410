<template>
  <div class="body">
    <Navbar v-bind:show-scroll-points="false"/>

    <b-row align-v="left" class="m-0">
      <b-col>
        <h1 class="title" v-html="$t('terms_title')"></h1>
        <div class="text text-left" v-html="$t('terms_text')"></div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import Navbar from '@/components/Navbar'

export default {
  name: 'TermsAndPrivacyPolicy',
  components: {
    Navbar,
  },
}
</script>

<style lang="less" scoped>
@import "../assets/css/styles";

.text {
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
  margin-bottom: 64px;
}

.title {
  margin-bottom: 24px;
  margin-top: 36px;
}

@media (min-width: @lg) {
  .text {
    max-width: 960px;
  }

  .title {
    margin-bottom: 36px;
    margin-top: 92px;
  }
}

</style>
